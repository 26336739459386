.judge_music_page_wrapper {
  .judge_music {
    padding: 1rem;
    border-radius: 8px;
    background-color: gray;
    margin-bottom: 1rem;

    p {
      text-align: center;
      color: white;

      &.pass {
        color: #7adf7a;
      }

      &.wait {
        color: #65b7e3;
      }

      &.deny {
        color: red !important;
      }
    }

    #song_info_btn, #song_pass_btn, #song_deny_btn {
      width: 100%;
      padding: 0.4rem 0;
      border: none;
      border-radius: 5px;
      color: white;
      margin-top: 0.5rem;

      &:hover {
        opacity: 0.5;
      }
    }

    #song_info_btn {
      background-image: linear-gradient(to right, #702283, #3CACE7);
    }

    #song_pass_btn {
      background-color: green;
    }

    #song_deny_btn {
      background-color: red;
    }
  }
}