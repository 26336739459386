.song_upload_wrapper {
  .upload_btn_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .song_status_header {
      select {
        padding: 15px 24px 15px 0;
        color: white;
        background: none;
        border: none;
        font-size: 1.2rem;
        font-weight: bold;
        cursor: pointer;


        &:focus-visible {
          outline: none;
        }
      }
    }

    button {
      background-image: linear-gradient(to right, #702283, #3CACE7);
      border-radius: 5px;
      color: white;
      padding: 15px 24px;
      border: none;
      font-weight: bold;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .upload_song_table_wrapper {
    margin-top: 1rem;

    .upload_song_table {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .song_status_header {
        h3 {
          margin: 0;
        }

        hr {
          margin-top: 0;
        }

        h3, hr {
          color: white;
          opacity: 1;
        }
      }

      .song_item {
        position: relative;
        cursor: pointer;
        margin-bottom: 1rem;

        img {
          width: 100%;
        }

        .song_info {
          visibility: hidden;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          padding: 1rem;
          background: rgba(0, 0, 0, 0.47);
          color: white;

          .genre_list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            p {
              margin-right: 0.2rem;
            }
          }
        }

        &:hover {
          .song_info {
            visibility: visible;
          }
        }
      }
    }
  }

}


.upload_song_dialog_wrapper {
  .upload_file_step_wrapper {
    padding: 1rem 0;

    label {
      display: block;
      color: white;
    }

    #upload_music_input, #upload_cover_input {
      display: none;
    }

    .upload_form_wrapper {
      margin-bottom: 1.8rem;
    }

    .upload_form {
      display: flex;
      justify-content: space-between;

      input, button {
        height: 40px;
      }

      input {
        width: 83%;
        padding: 0 0.5rem;
      }

      button {
        width: 15%;
        border: none;
        border-radius: 5px;
      }
    }
  }

  .upload_song_info_step_wrapper {
    padding: 1rem 0;

    .step_title {
      color: white;
    }

    select {
      width: 100%;
      height: 48px;
      padding: 0 0.5rem;
    }

    .editor_form_wrapper {
      margin-top: 1rem;

      .quill {
        background-color: white;
      }

      label {
        margin: 19px 0 8px;
        font-size: 14px;
        font-weight: 700;
        display: block;
        color: white;
      }
    }
  }
}


.upload_song_policy_wrapper {
  label {
    color: white;
    font-weight: bold;
  }

  h4 {
    margin-top: 1rem;
    color: white;
    padding: 0.5rem 0;
  }

  .policy_box {
    width: 100%;
    max-height: 250px;
    overflow: auto;
    background-color: var(--dark-color);
    padding: 0.5rem;

    p {
      color: white;
    }
  }
}