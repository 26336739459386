.notice_page_wrapper {
  table {
    width: 100%;

    tr:nth-of-type(even) {
      background-color: #222629;
    }

    thead {
      th {
        vertical-align: bottom !important;
        border-bottom: 2px solid #7d7d7d;
      }
    }

    th, td {
      color: white;
    }

    td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #7d7d7d;
    }

    th {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #7d7d7d;
    }

    .term_board_tbody {
      tr {
        cursor: pointer;

        &:hover {
          th, td {
            color: #8a64d1 !important;
          }
        }
      }
    }

    .term_board_no {
      vertical-align: middle;
      text-align: center;
      width: 10%;
    }

    .term_board_title {
      text-align: center;
      width: 70%;
      max-width: 500px;

    }

    .term_board_time {
      font-size: 0.8rem;
      vertical-align: middle;
      text-align: center;
      width: 20%;
    }
  }
}


.write_notice_page_wrapper {
  input {
    margin-bottom: 2rem;
  }

  .notice_sub_btn {
    margin-top: 55px;
    text-align: right;

    button {
      padding: 0.6rem 1.4rem;
      background-image: linear-gradient(to right, #702283, #3CACE7);
      color: white;
      font-weight: bold;
      border: none;
      border-radius: 6px;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}


.notice_detail_page_wrapper {
  margin-top: 3rem;

  .notice_time {
    text-align: right;
    color: white;
  }

  .notice_title {
    text-align: center;

    h3 {
      color: white;
      font-weight: bold;
    }
  }

  hr {
    color: #9676d3;
    border-top-width: 3px;
    opacity: 1;
  }

  .notice_content {
    color: white;
  }
}