.genre_chart_page {
  .genre_chips_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 2rem 0;
    flex-wrap: wrap;

    .genre_chip {
      background-color: white;
      border-radius: 50px;
      padding: 5px 18px;
      font-weight: bold;
      border: 1px solid white;
      margin-left: 1rem;
      margin-bottom: 1rem;

      &:hover {
        color: #af71ba;
        border-color: #af71ba;
      }

      &.active {
        background-color: #af71ba;
        color: white;
        border-color: #af71ba;
      }
    }
  }
}