.fileContainer {
  background: none !important;

  .chooseFileButton {
    width: 100%;
    max-width: 500px;
    padding: 0.7rem 0 !important;
    border-radius: 15px !important;
    font-weight: bold!important;
    background: #875ada !important;

    &:hover{
      background: #6a47ac !important;
    }
  }
}

.uploadPicturesWrapper {
  .deleteImage {
    background: #753EDC !important;
    font-size: 18px !important;
  }
}