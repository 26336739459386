.album_detail_wrapper, .song_detail_page_wrapper {
  .detail_info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .delete_btn_wrapper {
      flex: 1 0 0;
      text-align: right;

      button {
        padding: 0.65rem 1.3rem;
        border: none;
        border-radius: 6px;
        color: white;
        background-image: linear-gradient(to right, #702283, #3CACE7);

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .thumbnail {
      width: 240px;
      height: 240px;

      img {
        width: 100%;
      }
    }

    .description {
      padding: 10px 35px;
      color: white;

      .title {
        font-weight: bold;
      }

      .artist {

      }

      .genre {
        padding-top: 1rem;
      }

      .btn_wrapper {
        padding-top: 1rem;

        button {
          margin-right: 0.6rem;

          svg {
            width: 35px;
            height: 35px;
          }
        }
      }

    }
  }

  .album_music_chart_wrapper {
    margin-top: 40px;

  }

  .introduce_album, .introduce_song_wrapper {
    margin-top: 40px;

    p {
      padding: 1.5rem 0;
      color: white;
    }
  }

  .introduce_song_wrapper {
    .song_detail_info {
      dt {
        float: left;
        width: 60px;
        padding-top: 6px;
        color: #cecece;
        font-size: 16px;
      }

      dd {
        font-weight: bold;
        padding-top: 6px;
        margin-left: 60px;
        color: white;
        font-size: 18px;
      }
    }
  }
}

