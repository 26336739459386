.chart_header {
  color: var(--home-text-color);
  font-weight: 600;
}

.small_top_music_chart_wrapper {
  .chart_header {
    h3 {
      width: fit-content;
      cursor: pointer;

      &:hover {
        color: gray;
      }
    }
  }

  .chart_list {
    color: var(--home-text-color);
    font-weight: 600;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

    .small_chart_item_wrapper {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      max-height: 60px;
      cursor: pointer;
      margin-right: 1rem;

      .chart_item_image {
        max-width: 60px;
        flex: 0 0 60px;

        img {
          max-width: 100%;
        }
      }

      .chart_item_rank {
        max-width: 6rem;
        flex: 0 0 6rem;
        text-align: center;
      }

      .chart_item_rank, .chart_item_info {
        small {
          display: block;
        }
      }

      .chart_item_info {
        small {
          color: var(--home-nav-item-color);
        }
      }

      &:hover {
        background-color: rgba(128, 128, 128, 0.25);
      }
    }
  }
}

.small_new_release_chart_wrapper {
  margin: 3rem 0;

  .chart_list {
    .slick-slide {
      padding-left: 15px;
      padding-right: 15px;
    }

    .new_release_chart_item_wrapper {
      cursor: pointer;

      img {
        width: 100%;
        max-width: 100%;
        border-radius: 15px;
      }

      .song_info {
        padding: 0.5rem 0;

        span {
          font-weight: 600;
          display: block;

          &:first-child {
            font-size: 18px;
            color: var(--home-text-color);
          }

          &:last-child {
            font-size: 12px;
            color: var(--home-nav-item-color)
          }
        }
      }
    }
  }
}

.top_chart_table {

  .top_chart_row {
    td {
      padding: 4px 16px !important;

      &:nth-child(2), &:nth-child(3), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
        width: 80px;
        flex: 0 0 80px;
      }
    }
  }
}

.chart_rank_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #rank_num {
    font-weight: 600;
    font-size: 1.3rem;
  }

  .rank_up {
    color: #FF4E7E;
  }

  .rank_down {
    color: #8cbefa;
  }
}

.select_music {
  text-align: right;
  padding: 1rem 0;
  min-height: 75px;

  button {
    padding: 0.6rem 1.4rem;
    border: none;
    border-radius: 5px;
    background-color: #3CACE7;
    color: white;
  }
}

.chart_play_btn {
  background: none;
  border: none;
  color: white;
}