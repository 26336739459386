.my_studio_page_wrapper {
  padding: 2rem 0;

  .studio_profile_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .profile_img_wrapper {
      width: 200px;
      height: 200px;
      position: relative;

      img, button {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      button {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.63);
        color: white;
        font-weight: bold;
        border: none;
      }

    }


    .profile_info {
      padding: 0 2rem;

      #profile_name {
        color: white;
        font-weight: bold;
      }

      .profile_follow {
        display: flex;
        flex-direction: row;
        padding: 0.5rem 0;

        .follow_btn {
          background-image: linear-gradient(to right, #702283, #3CACE7);
          border-radius: 5px;
          color: white;
          padding: 8px 16px;
          border: none;
          font-weight: bold;

          &:hover {
            opacity: 0.5;
          }
        }

        .follow, .follower {
          text-align: center;
          color: white;
          margin-left: 1rem;

          span {

            &:first-child {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .my_studio_tabs_wrapper {
    margin-top: 2rem;

    .profile_studio_tab {
      max-width: 550px;

      button {
        margin-top: 1rem;
        width: 100%;
        padding: 0.6rem 0;
        border-radius: 6px;
        background-image: linear-gradient(to right, #702283, #3CACE7);
        color: white;
        border: none;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

}

.create_album_dialog_wrapper {
  .preview_section {
    text-align: center;

    img {
      width: 240px;
      height: 240px;
    }
  }

  .select_genre_wrapper {
    label {
      margin: 19px 0 8px;
      font-size: 14px;
      font-weight: 700;
      display: block;
      color: white;
    }

    .chip_list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1rem 0;

      .genre_chip {
        margin-bottom: 0.7rem;
        background: none;
        border: 1px solid #9974df;
        border-radius: 17px;
        color: white;
        padding: 8px 12px;

        &:hover, &.select {
          background: #9974df;
        }


      }
    }
  }

  .sub_btn {
    width: 100%;
    padding: 0.7rem 0;
    background-image: linear-gradient(to right, #702283, #3CACE7);
    border: none;
    border-radius: 15px;
    color: white;
    font-weight: bold;

    &:hover {
      opacity: 0.5;
    }
  }
}