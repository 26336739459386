.icon_btn {
  background: none;
  border: none;
  color: white;
  padding: 0;
}

button {
  &:disabled {
    background: gray;
    color: rgba(255, 255, 255, 0.73) !important;
    background-image: none !important;
  }
}