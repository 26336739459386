@import "~bootstrap/scss/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "notice";
@import "color";
@import "common";
@import "player";
@import "musicChart";
@import "input";
@import "button";
@import "dialog";
@import "fileUploader";
@import "./auth/auth";
@import "./navbar/navbar";
@import "./album/detail";
@import "./genre/genreChart";
@import "./upload/upload";
@import "./myStudio/myStudio";
@import "./admin/judge";