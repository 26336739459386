.music_player_wrapper {
  display: flex;
  flex-direction: row;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1004;
  height: 90px;
  border-top: 1px solid #4a4a4c;

  .music_info_section {
    width: 20%;
    background-color: #222629;
    display: flex;
    flex-direction: row;

    .music_cover {
      width: 90px;
      height: 90px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .music_txt {
      padding: 10px;

      p {
        font-weight: bold;
        font-size: 1.2rem;
      }

      p, small {
        color: white;
        display: block;
      }
    }
  }

  #audio_player {
    width: 80%;

    .rhap_container {
      background-color: #222629 !important;
      box-shadow: none;
      line-height: 0;
      height: 100%;

      .rhap_time {
        color: white !important;
      }

      .rhap_progress-filled, .rhap_progress-indicator {
        background-color: #3CACE7 !important;
      }
    }
  }
}

#audio_player {
  background-color: #343a40;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.4s all ease-in;
  -o-transition: 0.4s all ease-in;
  transition: 0.4s all ease-in;

  .song_info {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 37%;
    flex: 0 0 37%;
    max-width: 37%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .song_image {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 4.5rem;
      flex: 0 0 4.5rem;
      max-width: 4.5rem;

      img {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        max-width: 100%;
      }
    }

    .song_title {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 calc(100% - 4.5rem);
      flex: 0 0 calc(100% - 4.5rem);
      max-width: calc(100% - 4.5rem);
      padding-left: 1rem;

      span {
        display: block;
      }

      .song_name {
        color: #adb5bd;
        font-weight: 600;
        text-transform: capitalize;
      }

      .song_artist {
        color: #6c757d;
      }
    }
  }

  .song_controller {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 26%;
    flex: 0 0 26%;
    max-width: 26%;

    .song_controls_left {
      margin-right: auto;
    }

    .song_controls_right {
      margin-left: auto;
    }

    .song_controls_main {
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .audio_controller {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 37%;
    display: flex;
    flex: 0 0 37%;
    max-width: 37%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end !important;
    align-items: center;

    .song_time {
      color: #adb5bd;
      margin-right: 1.5rem;
    }
  }

  button {
    width: 2.5rem;
    height: 2.5rem;
    background-color: transparent;
    border: 1px solid transparent;

    svg {
      color: #3CACE7;
    }
  }


}

#play_list_toggle_btn {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  position: fixed;
  right: 10px;
  bottom: 100px;
  border: none;

}


.MuiDrawer-paperAnchorRight {
  width: 300px;

  .play_list {
    .playing{
      color: #753EDC !important;
    }
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0.3rem 0.5rem;
      cursor: pointer;

      dl {
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 0.2rem;

        dt, dd {
          font-size: 0.8rem;
        }

        dd {
          text-align: right;
          margin: 0;
        }
      }

      button {
        background: none;
        border: none;

        svg {
          color: white;
          width: 14px;
          height: 14px;
        }
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.12);
      }
    }
  }
}