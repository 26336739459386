
body {
  font-family: "Open Sans", sans-serif;
  background-color: #343a40;

  ul {
    padding-left: 0;
  }

  a {
    text-decoration: none;
  }

  a:not(.btn) {
    color: var(--home-nav-item-color);
  }

  p {
    margin: 0;
  }
}

ul {
  list-style: none;
}

.row {
  margin: 0 !important;
}

.text-truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

h5 {
  &.title {
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    color: white;
  }
}

.terms_wrapper, .privacy_wrapper {
  p {
    color: white;
  }
}