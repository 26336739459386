.label_input_wrapper {
  label {
    margin: 19px 0 8px;
    font-size: 14px;
    font-weight: 700;
    display: block;
  }

  .validation_error_msg {
    margin-top: 0.4rem;
    color: #8962d3;
  }
}


.auth_phone_wrapper {
  .success_auth_phone_txt {
    color: #8962d3;
    text-align: center;
    padding: 1rem 0;
  }

  .auth_num_form_wrapper {
    transition: height 1s;
    height: 0;
    visibility: hidden;
    &.show {
      height: 100px;
      visibility: visible;

    }
  }

  .auth_phone_form_wrapper, .auth_num_form_wrapper {
    align-items: flex-end;

    button {
      padding: 14px 17px 13px;
      border-radius: 6px;
      background-image: linear-gradient(to right, #702283, #3CACE7);
      color: white;
      border: none;

      &:hover {
        opacity: 0.5 !important;
      }
    }
  }

}

.form_input {
  width: 100%;
  height: 48px;
}