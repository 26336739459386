.swal2-container {
  z-index: 9999 !important;
}

.swal2-title {
  font-size: 1.6em !important;
}

.dialog_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.4rem;
  width: 100%;
  --bs-modal-width: 500px;

  .dialog_xl {
    width: auto;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
  }

  .dialog_title {
    color: white;
  }

  .dialog_background {
    background: #666666;
    padding: 2rem;
  }
}


@media (min-width: 576px) {

  .dialog_xl {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

}

@media (min-width: 992px) {
  .dialog_xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .dialog_xl {
    --bs-modal-width: 1140px;
  }
}