.nav_main_wrapper {

  .left_side_navbar_wrapper {
    background-color: #222629;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 17.5rem;
    transition: 0.4s all ease-in;

    .side_navbar_header {
      padding: 1rem;

      img {
        width: 100%;
      }
    }

    .side_navbar_links {
      margin-top: 2.5rem;

      .nav_item_box {
        .box_header {
          padding: 1rem 1.5rem 0.5rem 1.5rem;
          font-size: 0.75rem;
          text-transform: uppercase;
          letter-spacing: 3px;
          font-weight: 600;

          p {
            color: var(--home-text-color);
          }
        }

        .nav_link {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          padding: 0.625rem 1.5rem;

          span {
            margin-left: 0.6rem;
          }
        }
      }

    }
  }

  .nav_page_wrapper {
    margin-left: 17.5rem;
    padding: 0 2rem;
    min-height: 100vh;
    height: 100%;
    transition: 0.4s all ease-in;
    background-color: #343a40;
    position: relative;

    .nav_page_top_bar_wrapper {
      position: absolute;
      left: 15px;
      right: 15px;
      top: 15px;
      height: 50px;
      background-color: #753EDC;
      z-index: 8;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.5rem;


      .user_section {
        .user_profile {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 0.4rem;
          cursor: pointer;

          p {
            margin-left: 0.5rem;
            color: white;
            font-size: 0.875rem;
          }

          &:hover {
            background-color: rgba(255, 255, 255, 0.27);
            border-radius: 5px;
          }
        }

        .auth_btn_wrapper {
          a {
            color: white;

            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }

    .page_title {
      color: white;
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 20px;
    }

    .page_banner {
      background-size: cover;
      background-position: center center;
      height: 17rem;
      margin: 0 -2rem;
      position: relative;
      z-index: 1;

      &::before {
        content: '';
        display: block;
        background-color: rgba(34, 38, 41, 0.3);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &::after {
        content: '';
        display: block;
        background: linear-gradient(to bottom, rgba(52, 58, 64, 0) 0%, #343a40 78%, #343a40 100%);
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 10rem;
      }
    }
  }
}