.auth_background_wrapper {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dark-color);

  .auth_content_wrapper {
    margin: 100px 0;

    .auth_title_wrapper {
      padding: 1rem 0;
      text-align: center;
      color: white;
      font-weight: bold;
    }
  }

  .logo_wrapper {
    display: block;
    text-align: center;

    img {
      width: 250px;
    }

    padding-bottom: 50px;
  }
}

.login_page_wrapper, .register_page_wrapper {
  width: 460px;
  border-radius: 6px;
}

.login_page_wrapper {
  padding: 40px 20px;
  border: 1px solid gray;

  .login_form_wrapper {
    input {
      width: 100%;
      padding: 14px 17px 13px;

      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom: none;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  .login_btn_wrapper {
    margin-top: 40px;

    button {
      width: 100%;
      padding: 13px 0 13px;
      border-radius: 6px;
      border: none;
      color: white;
      background-image: linear-gradient(to right, #702283, #3CACE7);
    }
  }

  .google_btn_wrapper {
    margin-top: 40px;

    button {
      width: 100%;
      background: none;
      border: none;

      img {
      }
    }
  }

}

.auth_find_wrapper {
  text-align: center;
  padding: 20px 0 35px;

  li {
    position: relative;
    display: inline-block;

    &:nth-child(2), &:nth-child(3) {
      padding-left: 28px;

      &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 12px;
        width: 1px;
        height: 12px;
        border-radius: 0.5px;
        background-color: #dadada;
      }
    }

    a {
      display: inline-block;
      font-size: 14px;
      line-height: 17px;
      text-decoration: none;
      color: #888;
    }
  }
}


.register_page_wrapper {
  padding: 20px;

  label {
    color: white;
  }

  input {
    width: 100%;
    padding: 14px 17px 13px;
    border-radius: 6px;
  }

  .register_btn_wrapper {
    padding: 50px 0;

    button {
      color: white;
      background-image: linear-gradient(to right, #702283, #3CACE7);
      border-radius: 6px;
      width: 100%;
      padding: 13px 0 13px;
      border: none;
    }
  }
}